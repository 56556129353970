<template>
  <!-- tw-flex tw-fixed tw-left-0 tw-top-0 tw-bottom-0 tw-z-10 -->
  <div
    v-if="patientInfo"
    class="
      modal-patient-info
      tw-flex tw-w-screen tw-sticky tw-top-0 tw-overflow-hidden
      md:!tw-h-[90vh] xl:!tw-h-screen md:tw-relative
    "
    :class="[
      mobileClass,
      showInfo
        ? '!tw-h-[125px] md:tw-w-[77px]'
        : 'tw-h-[calc(100vh-80px)] md:tw-h-screen md:tw-w-[320px]',
    ]"
  >
    <div
      class="
        mini-side
        tw-hidden
        tw-relative
        tw-flex-none
        tw-flex-col
        tw-items-center
        tw-py-10
        tw-overflow-hidden
        md:tw-flex
      "
    >
      <div class="tw-flex-1 tw-flex tw-flex-col tw-items-center">
        <b-avatar
          size="40px"
          variant="info"
          :src="patientInfo.profileUrl"
          class="tw-transition-transform tw-duration-500 tw-z-[10]"
          :class="
            showInfo
              ? 'tw-transform-gpu tw-scale-100 tw-translate-x-0'
              : 'tw-transform-gpu tw-scale-150 tw-translate-x-2 tw-delay-300'
          "
        ></b-avatar>
        <div
          class="mini-info tw-duration-500"
          :class="
            showInfo
              ? 'tw-transform-gpu tw-translate-x-0 tw-opacity-100'
              : 'tw-transform-gpu tw--translate-x-20 tw-opacity-0'
          "
        >
          <p
            class="
              tw-text-base tw-capitalize tw-text-center tw-mt-4
              text-orientation
            "
          >
            <span>{{ patientInfo.firstname }}</span>
            <span>{{ patientInfo.lastname && patientInfo.lastname[0] }}.</span>
            <span class="tw-mt-5 tw-text-[#4F4F4F]"
              >{{ patientInfo.age }} {{ patientInfo.state }}</span
            >
          </p>
        </div>
      </div>

      <div
        class="
          tw-uppercase
          text-orientation
          tw-flex-1
          tw-flex
          tw-justify-center
          tw-select-none
          tw-tracking-[0.1em]
          tw-cursor-pointer
          tw-text-[#FF8D40]
          tw-font-atten-medium
        "
        @click="toggle()"
      >
        <template v-if="showInfo"> Show Patient Details </template>
        <template v-else> Hide Patient Details </template>
      </div>
      <div class="tw-flex-1 tw-flex tw-flex-col tw-justify-end tw-items-center">
        <div class="tw-flex tw-justify-center tw-items-center tw-mb-10">
          <div
            class="
              tw-cursor-pointer
              tw-bg-warning
              tw-w-[40px]
              tw-h-[40px]
              tw-rounded-full
              message-icon
            "
            @click="$router.push(`/patients/${patientInfo.id}/message`)"
          ></div>
        </div>
        <div
          @click="toggle()"
          class="back-arrow tw-transition-transform tw-duration-500"
          :class="
            showInfo
              ? 'tw-transform-gpu tw--rotate-180'
              : 'tw-transform-gpu tw-rotate-0'
          "
        ></div>
      </div>
    </div>

    <div
      class="
        mobile-patient-header
        tw-flex tw-flex-grow tw-p-4 tw-relative tw-z-20 tw-h-[125px]
        md:tw-hidden
      "
    >
      <div class="tw-flex-none">
        <b-avatar
          size="85px"
          variant="info"
          :src="patientInfo.profileUrl"
        ></b-avatar>
      </div>
      <div class="tw-pl-2 tw-flex-grow">
        <p class="tw-capitalize tw-text-base tw-leading-[15px] tw-mb-3 tw-mt-2">
          {{ patientInfo.firstname }} {{ patientInfo.lastname }}
        </p>
        <b-row class="tw-mb-2">
          <b-col cols="6" class="tw-mb-2">
            <b-button
              block
              size="sm"
              variant="outline-default"
              pill
              class="
                tw-capitalize tw-flex tw-justify-center tw-items-center
                hover:tw-bg-black hover:tw-text-white
                tw-tracking-tight
                md:tw-tracking-normal
              "
              @click="$emit('update:showNotes', true)"
            >
              <v-icon
                name="note"
                variant=""
                size="20"
                color="currentColor"
                class="tw-mr-2"
              />
              Notes
              <div
                class="tw-text-sm tw-ml-1"
                v-if="patientInfo.medicalnotescount > 0"
              >
                <b-badge class="tw-text-white" pill>
                  {{ patientInfo.medicalnotescount }}
                </b-badge>
              </div>
            </b-button>
          </b-col>
          <b-col cols="6">
            <b-button
              block
              size="sm"
              variant="outline-default"
              pill
              class="
                tw-capitalize tw-flex tw-justify-center tw-items-center
                hover:tw-bg-black hover:tw-text-white
                tw-tracking-tight
                md:tw-tracking-normal
              "
              v-if="!currentDoctor.id"
              @click="$bvModal.show('setReminder')"
            >
              <v-icon
                name="reminder"
                size="22"
                color="currentColor"
                variant=""
              />
              <span class="tw-hidden md:tw-inline-block">Set Reminder</span>
              <span class="tw-inline-block md:tw-hidden">S.Reminder</span>
            </b-button>
          </b-col>
        </b-row>
        <div
          class="
            tw-uppercase
            tw-flex
            tw-select-none
            tw-tracking-[0.1em]
            tw-cursor-pointer
            tw-text-[#FF8D40]
            tw-font-atten-medium
            tw-text-xs
          "
          @click="toggle()"
        >
          <template v-if="showInfo"> Show Patient Details </template>
          <template v-else> Hide Patient Details </template>
        </div>
      </div>
    </div>

    <div
      class="
        side-content
        tw-absolute
        tw-top-0
        tw-right-0
        tw-bottom-0
        tw-flex
        tw-flex-col
        tw-pt-10
        tw-px-5
        tw-duration-500
        tw-mt-[125px]
        tw-z-10
        md:tw-pt-10 md:tw-z-0 md:tw-mt-0
      "
      :class="
        showInfo
          ? 'tw-transform-gpu tw--translate-y-40 md:tw--translate-y-0 md:tw--translate-x-20 tw-opacity-0 tw-duration-500'
          : 'tw-transform-gpu tw-translate-y-0 md:tw-translate-x-0 tw-opacity-100 tw-delay-300'
      "
    >
      <div class="tw-flex-shrink-0 tw-hidden md:tw-block">
        <p class="tw-capitalize tw-text-lg tw-leading-[15px] tw-mb-3">
          {{ patientInfo.firstname }} {{ patientInfo.lastname }}
        </p>
        <!-- button -->
        <b-row class="tw-mb-6">
          <b-col cols="9" class="tw-mb-2">
            <b-button
              block
              size="sm"
              variant="outline-default"
              pill
              class="
                tw-capitalize tw-flex tw-justify-center tw-items-center
                hover:tw-bg-black hover:tw-text-white
              "
              @click="$emit('update:showNotes', true)"
            >
              <v-icon
                name="note"
                variant=""
                size="20"
                color="currentColor"
                class="tw-mr-2"
              />
              Notes
              <div
                class="tw-text-sm tw-ml-1"
                v-if="patientInfo.medicalnotescount > 0"
              >
                <b-badge class="tw-text-white" pill>
                  {{ patientInfo.medicalnotescount }}
                </b-badge>
              </div>
            </b-button>
          </b-col>
          <b-col cols="9" v-if="!currentDoctor.id">
            <b-button
              block
              size="sm"
              variant="outline-default"
              pill
              class="
                tw-capitalize tw-flex tw-justify-center tw-items-center
                hover:tw-bg-black hover:tw-text-white
              "
              @click="$bvModal.show('setReminder')"
            >
              <v-icon name="reminder" color="currentColor" variant="" />
              Set Reminder
            </b-button>
          </b-col>
        </b-row>
      </div>

      <div class="tw-flex-grow tw-overflow-x-hidden tw-overflow-y-auto">
        <!-- patient info -->
        <b-row>
          <b-col cols="12" class="tw-mb-3">
            <b-row>
              <b-col cols="12">
                <a
                  v-if="!isViewingContactInfo"
                  class="
                    tw-uppercase
                    hover:tw-underline
                    tw-text-xs tw-tracking-wider tw-cursor-pointer
                  "
                  @click="isViewingContactInfo = true"
                >
                  Contact Info & Address
                </a>
                <a
                  v-else
                  class="
                    tw-uppercase
                    hover:tw-underline
                    tw-text-xs
                    tw-tracking-wider
                    tw-cursor-pointer
                    tw-inline-flex
                    tw-items-center
                  "
                  @click="isViewingContactInfo = false"
                >
                  <svg
                    width="16px"
                    height="8px"
                    viewBox="0 0 16 8"
                    style="
                      fill-rule: evenodd;
                      clip-rule: evenodd;
                      stroke-linejoin: round;
                      stroke-miterlimit: 2;
                    "
                  >
                    <g transform="matrix(1,0,0,1,-1232.66,-1750.33)">
                      <path
                        d="M1232.66,1753.77L1232.66,1753.77L1232.66,1754.97C1234.55,1754.97 1235.52,1756.6 1235.52,1757.54L1236.72,1757.54C1236.72,1756.72 1236.23,1755.51 1235.24,1754.68L1247.66,1754.68L1247.66,1753.48L1235.11,1753.48C1236.18,1752.57 1236.72,1751.18 1236.72,1750.33L1235.52,1750.33C1235.52,1751.29 1234.57,1753.19 1232.66,1753.19L1232.66,1753.77Z"
                        style="fill: rgb(255, 141, 64)"
                      />
                    </g>
                  </svg>
                  <span class="tw-inline-block ml-1">Back</span>
                </a>
              </b-col>
            </b-row>
          </b-col>
          <template v-if="!isViewingContactInfo">
            <b-col cols="12" class="tw-mb-3">
              <b-row>
                <b-col cols="6">
                  <small class="tw-block tw-text-[#4F4F4F] tw-mb-1"
                    >Date of Birth</small
                  >
                  <p>{{ patientInfo.birthday }}</p>
                </b-col>
                <b-col cols="3">
                  <small class="tw-block tw-text-[#4F4F4F] tw-mb-1">Age</small>
                  <p>{{ patientInfo.age }}</p>
                </b-col>
                <b-col cols="3">
                  <small class="tw-block tw-text-[#4F4F4F] tw-mb-1"
                    >State</small
                  >
                  <p>{{ patientInfo.state }}</p>
                </b-col>
              </b-row>
            </b-col>

            <!-- <b-col cols="12" class="tw-mb-3">
              <b-row>
                <b-col cols="7">
                  <small class="tw-block tw-text-[#4F4F4F] tw-mb-1"
                    >Gender</small
                  >
                  <p>{{ patientInfo.gender }}</p>
                </b-col>
                <b-col cols="5">
                  <small class="tw-block tw-text-[#4F4F4F] tw-mb-1"
                    >State, Zip</small
                  >
                  <p>
                    {{ patientInfo.state }},
                    {{ patientInfo.defaultaddress.zipcode }}
                  </p>
                </b-col>
              </b-row>
            </b-col> -->

            <b-col cols="12" class="tw-mb-3">
              <b-row>
                <b-col cols="12">
                  <small class="tw-block tw-text-[#4F4F4F] tw-mb-1">
                    Patient Notes
                  </small>
                  <p>
                    {{ patientInfo.patientnotes || "No Information Available" }}
                  </p>
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              class="tw-mb-3"
              v-if="
                patientInfo.unselectedproduct &&
                patientInfo.unselectedproduct.length > 0
              "
            >
              <b-row>
                <b-col cols="12">
                  <small class="tw-block tw-text-[#4F4F4F] tw-mb-1">
                    Declined onboarding treatment
                  </small>
                  <ul class="list-unstyled">
                    <li
                      v-for="(plan, i) in patientInfo.unselectedproduct"
                      :key="i"
                    >
                      {{ plan.name || "" }}
                    </li>
                  </ul>
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              class="tw-mb-3"
              v-if="patientInfo.followupdatestamp"
            >
              <b-row>
                <b-col cols="12">
                  <small class="tw-block tw-text-[#4F4F4F] tw-mb-1">
                    Follow-up Date
                  </small>
                  <p>
                    {{
                      parseDate(patientInfo.followupdatestamp, "MMMM d, yyyy")
                    }}
                  </p>
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              class="tw-mb-3"
              v-if="patientInfo.upsell && patientInfo.upsell.length > 0"
            >
              <b-row>
                <b-col cols="12">
                  <small class="tw-block tw-text-warning tw-mb-1 tw-uppercase">
                    Prescription Request
                  </small>
                  <ul class="list-unstyled">
                    <li v-for="(upsell, i) in patientInfo.upsell" :key="i">
                      {{ upsell.name }}
                      <span v-if="upsell.subscriptiontype">
                        ({{ upsell.subscriptiontype }})
                      </span>
                    </li>
                  </ul>
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              class="tw-mb-3"
              v-if="patientInfo.followupdatestamp"
            >
              <b-row>
                <b-col cols="12">
                  <small class="tw-block tw-text-[#4F4F4F] tw-mb-1">
                    Preferred Shipment
                  </small>
                  <p>
                    {{
                      patientInfo.preferredshipmentname ||
                      "No Information Available"
                    }}
                  </p>
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="12" class="tw-mb-3">
              <b-row>
                <b-col cols="12">
                  <small class="tw-block tw-text-[#4F4F4F] tw-mb-1">
                    Price Version
                  </small>
                  <a
                    class="
                      text-secondary
                      tw-cursor-pointer tw-inline-block tw-mb-4
                    "
                    @click="$emit('update:showPricing', true)"
                  >
                    {{
                      patientInfo.pricingversion === "v2"
                        ? "Version 2"
                        : "Version 1"
                    }}
                  </a>
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              class="tw-mb-3"
              v-if="
                patientInfo.othermedication &&
                patientInfo.othermedication.length > 0
              "
            >
              <b-row align-v="center">
                <b-col cols="12" md="12">
                  <p class="tw-block tw-text-[#4F4F4F] tw-mb-1">
                    <small>Other Medications</small>
                  </p>
                  <div v-if="patientInfo.othermedication">
                    <h4
                      v-for="(medication, i) in patientInfo.othermedication"
                      :key="i"
                    >
                      {{ medication }}
                    </h4>
                  </div>
                  <h4 v-else class="text-muted">No records!</h4>
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              class="tw-mb-3"
              v-if="
                patientInfo.treamentplandetail &&
                patientInfo.treamentplandetail.length === 0
              "
            >
              <b-row>
                <b-col cols="12">
                  <small class="tw-block tw-text-[#4F4F4F] tw-mb-1">
                    Recommended Treatment
                  </small>
                  <ul class="list-unstyled" v-if="patientInfo.desiredplan">
                    <li v-for="(plan, i) in patientInfo.desiredplan" :key="i">
                      {{ plan.name || "" }}
                    </li>
                  </ul>
                  <ul
                    class="list-unstyled"
                    v-else-if="patientInfo.preferredtreatment"
                  >
                    <li>
                      {{ patientInfo.preferredtreatment || "" }}
                    </li>
                  </ul>
                  <ul class="list-unstyled" v-else>
                    <li>Let doctor choose medication</li>
                  </ul>
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              class="tw-mb-3"
              v-if="
                patientInfo.treamentplandetail &&
                patientInfo.treamentplandetail.length > 0
              "
            >
              <b-row align-v="center">
                <b-col cols="12" md="12">
                  <h3
                    class="tw-block tw-text-[#4F4F4F] tw-mb-2"
                    style="font-size: 16px"
                  >
                    Active Treatment Plans
                  </h3>
                  <div>
                    <div
                      v-for="(treat, a) in patientInfo.treamentplandetail"
                      :key="a"
                    >
                      <b-row>
                        <b-col cols="12" class="mb-2">
                          <small class="text-black" style="font-size: 14px">
                            {{ treat.description }}
                          </small>
                          <small class="tw-block">
                            {{ moment.unix(treat.date).format("MM/DD/YY") }}
                          </small>
                          <b-row>
                            <b-col>
                              <b-badge
                                style="
                                  padding: 5px;
                                  border-radius: 9999px;
                                  display: inline-block;
                                "
                                :class="[
                                  'mr-2',
                                  treat.status == 'PAUSED'
                                    ? 'badge-warning'
                                    : treat.status == 'CANCELLED'
                                    ? 'badge-danger'
                                    : treat.status == 'ACCEPTED'
                                    ? 'badge-success'
                                    : treat.status == 'PRESCRIBED'
                                    ? 'badge-success'
                                    : treat.status == 'SCHEDULED'
                                    ? 'badge-purple'
                                    : '',
                                ]"
                              ></b-badge>
                              <small>{{ treat.status }}</small>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </template>
          <template v-else>
            <b-col cols="12" class="tw-mb-3">
              <b-row>
                <b-col cols="6">
                  <small class="tw-block tw-text-[#4F4F4F] tw-mb-1"
                    >Date of Birth</small
                  >
                  <p>{{ patientInfo.birthday }}</p>
                </b-col>
                <b-col cols="3">
                  <small class="tw-block tw-text-[#4F4F4F] tw-mb-1">Age</small>
                  <p>{{ patientInfo.age }}</p>
                </b-col>
                <b-col cols="3">
                  <small class="tw-block tw-text-[#4F4F4F] tw-mb-1"
                    >State</small
                  >
                  <p>{{ patientInfo.state }}</p>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" class="tw-mb-3">
              <b-row>
                <b-col cols="12">
                  <small class="tw-block tw-text-[#4F4F4F] tw-mb-1"
                    >Address</small
                  >
                  <p>
                    {{
                      `${patientInfo.defaultaddress.address1} ${
                        patientInfo.defaultaddress.address2 || ""
                      } ${patientInfo.defaultaddress.city} ${
                        patientInfo.defaultaddress.zipcode
                      }` || ""
                    }}
                  </p>
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              class="tw-mb-3"
              v-if="
                patientInfo.defaultaddress &&
                patientInfo.defaultaddress.timezone
              "
            >
              <b-row>
                <b-col cols="12">
                  <small class="tw-block tw-text-[#4F4F4F] tw-mb-1">
                    Timezone
                  </small>
                  <p>{{ patientInfo.defaultaddress.timezone }}</p>
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="12" class="tw-mb-3">
              <b-row>
                <b-col cols="12">
                  <small class="tw-block tw-text-[#4F4F4F] tw-mb-1">
                    Email Address
                  </small>
                  <p style="word-break: break-all">{{ patientInfo.email }}</p>
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="12" class="tw-mb-3">
              <b-row>
                <b-col cols="12">
                  <small class="tw-block tw-text-[#4F4F4F] tw-mb-1">
                    Contact Number
                  </small>
                  <p>{{ patientInfo.phoneno || "No Information Available" }}</p>
                </b-col>
              </b-row>
            </b-col>
          </template>
        </b-row>
      </div>

      <div class="tw-flex-shrink-0 tw-sticky tw-bottom-0 tw-pt-4">
        <!-- Prescribe, view profile, open dosepot -->
        <b-row class="tw-mb-6" v-if="$route.name === 'patientmessage'">
          <b-col cols="12" class="tw-mb-2" v-if="!currentDoctor.id">
            <b-button
              block
              variant="secondary"
              pill
              :to="`/prescriptions/order/${patientInfo.id}`"
              v-if="!patientInfo.isbanned"
            >
              Prescribe
            </b-button>
          </b-col>
          <b-col cols="12" class="tw-mb-2">
            <b-button
              block
              variant="outline-secondary"
              pill
              :to="`/patients/${patientInfo.id}/treatments`"
            >
              View Profile
            </b-button>
          </b-col>
          <b-col cols="12" v-if="!currentDoctor.id">
            <b-button
              block
              variant="outline-secondary"
              pill
              @click="$emit('show-dosespot')"
            >
              Open Dosespot
            </b-button>
          </b-col>
          <b-col cols="12">
            <portal-target name="needsreply"></portal-target>
          </b-col>
        </b-row>

        <!-- Patient Profile Side Bar -->
        <div class="tw-mb-6" v-if="$route.name === 'treatments'">
          <div class="tw-flex tw-justify-center">
            <b-button
              block
              variant="link"
              pill
              @click="$emit('switch-view', 'prescription-history')"
            >
              Prescription
            </b-button>
          </div>
          <div class="tw-flex tw-justify-center">
            <b-button
              block
              variant="link"
              pill
              @click="$emit('switch-view', 'reminders')"
            >
              reminders
            </b-button>
          </div>
          <div class="tw-flex tw-justify-center">
            <b-button
              block
              variant="link"
              pill
              @click="$emit('switch-view', 'followup')"
            >
              follow-up history
            </b-button>
          </div>
          <div class="tw-flex tw-justify-center">
            <b-button
              block
              variant="link"
              pill
              :to="`/patients/${patientInfo.id}/intake`"
            >
              Intake History
            </b-button>
          </div>
          <div v-if="!currentDoctor.id">
            <b-button
              block
              variant="secondary"
              pill
              :to="`/patients/${patientInfo.id}/message`"
            >
              Chat with patient
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format, fromUnixTime } from "date-fns";
import { isMobile } from "mobile-device-detect";

export default {
  props: {
    patientInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showInfo: false,
      isViewingContactInfo: false,
      isIos: false,
      mobileClass: "",
    };
  },
  mounted() {
    if (isMobile) {
      this.showInfo = true;
    } else {
      this.showInfo = false;
    }

    try {
      // /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)
      var isIphone = /(iPhone)/i.test(navigator.platform);

      if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
        this.mobileClass = "isAndroid";
        this.isIos = false;
      }

      if (isIphone) {
        this.mobileClass = "isIos";
        this.isIos = true;
      }
    } catch (err) {
      console.log(err);
    }
  },
  computed: {
    currentDoctor() {
      return this.$store.state.globals.currentDoctor;
    },
  },
  methods: {
    toggle() {
      this.showInfo = !this.showInfo;
    },
    parseDate(date, stringFormat) {
      return format(fromUnixTime(date), stringFormat);
    },
  },
};
</script>

<style lang="scss" scoped>
$mini-width: 77px;
$max-wdith: 320px;

.isIos {
  height: calc(100vh - (125px + 72px));
}

.modal-patient-info {
  background-color: #ffecca;
  transition: height 500ms;
  will-change: height; // to cache animation

  @include media-breakpoint-up(md) {
    transition: width 500ms;
    will-change: width; // to cache animation
  }

  .mini-side {
    width: $mini-width;
    z-index: 10;
    background-color: #ffecca;

    .mini-info {
      transition-property: opacity, transform;
      will-change: opacity; // to cache animation
    }
  }

  .side-content {
    overflow: hidden;
    transition-property: opacity, transform;
    will-change: opacity; // to cache animation

    @include media-breakpoint-up(md) {
      width: calc(#{$max-wdith} - #{$mini-width});
    }
  }

  .text-orientation {
    writing-mode: vertical-lr;
    text-orientation: sideways;
    // transform: rotate(180deg);
  }
}

.message-icon {
  width: 40px;
  height: 40px;
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyNSAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIwLjY2NDggNC44MTkwOUwyNC4wMDAxIDcuMTEyNzZMMTIuNjU0NSAxMy4xNzU2SDEyLjY0NDNMMS4wMTA4NiA3LjExMjc2TDQuNDMwOTEgNC44MTkwOSIgc3Ryb2tlPSIjRkZFQ0NBIiBzdHJva2Utd2lkdGg9IjEuNiIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMSA3LjEwMzUyVjIwLjU4NzlIMTIuNzU2MSIgc3Ryb2tlPSIjRkZFQ0NBIiBzdHJva2Utd2lkdGg9IjEuNiIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMjMuOTk5OCA3LjEwMzUyVjIwLjU4NzlIMTIuMDQ2NSIgc3Ryb2tlPSIjRkZFQ0NBIiBzdHJva2Utd2lkdGg9IjEuNiIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNNC42MDU0NyA4Ljk4NjkyVjFIMjAuNjQyMVY4LjgzMzUiIHN0cm9rZT0iI0ZGRUNDQSIgc3Ryb2tlLXdpZHRoPSIxLjYiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTguMzk5NTQgNC41OTM4N0gxNi44MTc0IiBzdHJva2U9IiNGRkVDQ0EiIHN0cm9rZS13aWR0aD0iMS42IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
}

.btn-outline-default {
  border: 1px solid #19191c;
}

.darkmode .btn-outline-default {
  border: 1px solid #fff;
  color: #fff;
}

.mobile-patient-header {
  background-color: #ffecca;
}

.darkmode .mobile-patient-header {
  background-color: #000;
}

.darkmode .modal-patient-info {
  background-color: #000;

  .mini-side {
    width: $mini-width;
    position: relative;
    z-index: 10;
    background-color: #000;
  }
}

.badge-purple {
  background-color: #a783ff;
}
</style>
