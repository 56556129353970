var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.patientInfo
    ? _c(
        "div",
        {
          staticClass:
            "modal-patient-info tw-flex tw-w-screen tw-sticky tw-top-0 tw-overflow-hidden md:!tw-h-[90vh] xl:!tw-h-screen md:tw-relative",
          class: [
            _vm.mobileClass,
            _vm.showInfo
              ? "!tw-h-[125px] md:tw-w-[77px]"
              : "tw-h-[calc(100vh-80px)] md:tw-h-screen md:tw-w-[320px]",
          ],
        },
        [
          _c(
            "div",
            {
              staticClass:
                "mini-side tw-hidden tw-relative tw-flex-none tw-flex-col tw-items-center tw-py-10 tw-overflow-hidden md:tw-flex",
            },
            [
              _c(
                "div",
                {
                  staticClass: "tw-flex-1 tw-flex tw-flex-col tw-items-center",
                },
                [
                  _c("b-avatar", {
                    staticClass:
                      "tw-transition-transform tw-duration-500 tw-z-[10]",
                    class: _vm.showInfo
                      ? "tw-transform-gpu tw-scale-100 tw-translate-x-0"
                      : "tw-transform-gpu tw-scale-150 tw-translate-x-2 tw-delay-300",
                    attrs: {
                      size: "40px",
                      variant: "info",
                      src: _vm.patientInfo.profileUrl,
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "mini-info tw-duration-500",
                      class: _vm.showInfo
                        ? "tw-transform-gpu tw-translate-x-0 tw-opacity-100"
                        : "tw-transform-gpu tw--translate-x-20 tw-opacity-0",
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "tw-text-base tw-capitalize tw-text-center tw-mt-4 text-orientation",
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.patientInfo.firstname)),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.patientInfo.lastname &&
                                  _vm.patientInfo.lastname[0]
                              ) + "."
                            ),
                          ]),
                          _c(
                            "span",
                            { staticClass: "tw-mt-5 tw-text-[#4F4F4F]" },
                            [
                              _vm._v(
                                _vm._s(_vm.patientInfo.age) +
                                  " " +
                                  _vm._s(_vm.patientInfo.state)
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "tw-uppercase text-orientation tw-flex-1 tw-flex tw-justify-center tw-select-none tw-tracking-[0.1em] tw-cursor-pointer tw-text-[#FF8D40] tw-font-atten-medium",
                  on: {
                    click: function ($event) {
                      return _vm.toggle()
                    },
                  },
                },
                [
                  _vm.showInfo
                    ? [_vm._v(" Show Patient Details ")]
                    : [_vm._v(" Hide Patient Details ")],
                ],
                2
              ),
              _c(
                "div",
                {
                  staticClass:
                    "tw-flex-1 tw-flex tw-flex-col tw-justify-end tw-items-center",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "tw-flex tw-justify-center tw-items-center tw-mb-10",
                    },
                    [
                      _c("div", {
                        staticClass:
                          "tw-cursor-pointer tw-bg-warning tw-w-[40px] tw-h-[40px] tw-rounded-full message-icon",
                        on: {
                          click: function ($event) {
                            return _vm.$router.push(
                              `/patients/${_vm.patientInfo.id}/message`
                            )
                          },
                        },
                      }),
                    ]
                  ),
                  _c("div", {
                    staticClass:
                      "back-arrow tw-transition-transform tw-duration-500",
                    class: _vm.showInfo
                      ? "tw-transform-gpu tw--rotate-180"
                      : "tw-transform-gpu tw-rotate-0",
                    on: {
                      click: function ($event) {
                        return _vm.toggle()
                      },
                    },
                  }),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "mobile-patient-header tw-flex tw-flex-grow tw-p-4 tw-relative tw-z-20 tw-h-[125px] md:tw-hidden",
            },
            [
              _c(
                "div",
                { staticClass: "tw-flex-none" },
                [
                  _c("b-avatar", {
                    attrs: {
                      size: "85px",
                      variant: "info",
                      src: _vm.patientInfo.profileUrl,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "tw-pl-2 tw-flex-grow" },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "tw-capitalize tw-text-base tw-leading-[15px] tw-mb-3 tw-mt-2",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.patientInfo.firstname) +
                          " " +
                          _vm._s(_vm.patientInfo.lastname) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "b-row",
                    { staticClass: "tw-mb-2" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "tw-mb-2", attrs: { cols: "6" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass:
                                "tw-capitalize tw-flex tw-justify-center tw-items-center hover:tw-bg-black hover:tw-text-white tw-tracking-tight md:tw-tracking-normal",
                              attrs: {
                                block: "",
                                size: "sm",
                                variant: "outline-default",
                                pill: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("update:showNotes", true)
                                },
                              },
                            },
                            [
                              _c("v-icon", {
                                staticClass: "tw-mr-2",
                                attrs: {
                                  name: "note",
                                  variant: "",
                                  size: "20",
                                  color: "currentColor",
                                },
                              }),
                              _vm._v(" Notes "),
                              _vm.patientInfo.medicalnotescount > 0
                                ? _c(
                                    "div",
                                    { staticClass: "tw-text-sm tw-ml-1" },
                                    [
                                      _c(
                                        "b-badge",
                                        {
                                          staticClass: "tw-text-white",
                                          attrs: { pill: "" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.patientInfo
                                                  .medicalnotescount
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "6" } },
                        [
                          !_vm.currentDoctor.id
                            ? _c(
                                "b-button",
                                {
                                  staticClass:
                                    "tw-capitalize tw-flex tw-justify-center tw-items-center hover:tw-bg-black hover:tw-text-white tw-tracking-tight md:tw-tracking-normal",
                                  attrs: {
                                    block: "",
                                    size: "sm",
                                    variant: "outline-default",
                                    pill: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$bvModal.show("setReminder")
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", {
                                    attrs: {
                                      name: "reminder",
                                      size: "22",
                                      color: "currentColor",
                                      variant: "",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "tw-hidden md:tw-inline-block",
                                    },
                                    [_vm._v("Set Reminder")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "tw-inline-block md:tw-hidden",
                                    },
                                    [_vm._v("S.Reminder")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "tw-uppercase tw-flex tw-select-none tw-tracking-[0.1em] tw-cursor-pointer tw-text-[#FF8D40] tw-font-atten-medium tw-text-xs",
                      on: {
                        click: function ($event) {
                          return _vm.toggle()
                        },
                      },
                    },
                    [
                      _vm.showInfo
                        ? [_vm._v(" Show Patient Details ")]
                        : [_vm._v(" Hide Patient Details ")],
                    ],
                    2
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "side-content tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-flex tw-flex-col tw-pt-10 tw-px-5 tw-duration-500 tw-mt-[125px] tw-z-10 md:tw-pt-10 md:tw-z-0 md:tw-mt-0",
              class: _vm.showInfo
                ? "tw-transform-gpu tw--translate-y-40 md:tw--translate-y-0 md:tw--translate-x-20 tw-opacity-0 tw-duration-500"
                : "tw-transform-gpu tw-translate-y-0 md:tw-translate-x-0 tw-opacity-100 tw-delay-300",
            },
            [
              _c(
                "div",
                { staticClass: "tw-flex-shrink-0 tw-hidden md:tw-block" },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "tw-capitalize tw-text-lg tw-leading-[15px] tw-mb-3",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.patientInfo.firstname) +
                          " " +
                          _vm._s(_vm.patientInfo.lastname) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "b-row",
                    { staticClass: "tw-mb-6" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "tw-mb-2", attrs: { cols: "9" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass:
                                "tw-capitalize tw-flex tw-justify-center tw-items-center hover:tw-bg-black hover:tw-text-white",
                              attrs: {
                                block: "",
                                size: "sm",
                                variant: "outline-default",
                                pill: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("update:showNotes", true)
                                },
                              },
                            },
                            [
                              _c("v-icon", {
                                staticClass: "tw-mr-2",
                                attrs: {
                                  name: "note",
                                  variant: "",
                                  size: "20",
                                  color: "currentColor",
                                },
                              }),
                              _vm._v(" Notes "),
                              _vm.patientInfo.medicalnotescount > 0
                                ? _c(
                                    "div",
                                    { staticClass: "tw-text-sm tw-ml-1" },
                                    [
                                      _c(
                                        "b-badge",
                                        {
                                          staticClass: "tw-text-white",
                                          attrs: { pill: "" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.patientInfo
                                                  .medicalnotescount
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      !_vm.currentDoctor.id
                        ? _c(
                            "b-col",
                            { attrs: { cols: "9" } },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass:
                                    "tw-capitalize tw-flex tw-justify-center tw-items-center hover:tw-bg-black hover:tw-text-white",
                                  attrs: {
                                    block: "",
                                    size: "sm",
                                    variant: "outline-default",
                                    pill: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$bvModal.show("setReminder")
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", {
                                    attrs: {
                                      name: "reminder",
                                      color: "currentColor",
                                      variant: "",
                                    },
                                  }),
                                  _vm._v(" Set Reminder "),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "tw-flex-grow tw-overflow-x-hidden tw-overflow-y-auto",
                },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "tw-mb-3", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", { attrs: { cols: "12" } }, [
                                !_vm.isViewingContactInfo
                                  ? _c(
                                      "a",
                                      {
                                        staticClass:
                                          "tw-uppercase hover:tw-underline tw-text-xs tw-tracking-wider tw-cursor-pointer",
                                        on: {
                                          click: function ($event) {
                                            _vm.isViewingContactInfo = true
                                          },
                                        },
                                      },
                                      [_vm._v(" Contact Info & Address ")]
                                    )
                                  : _c(
                                      "a",
                                      {
                                        staticClass:
                                          "tw-uppercase hover:tw-underline tw-text-xs tw-tracking-wider tw-cursor-pointer tw-inline-flex tw-items-center",
                                        on: {
                                          click: function ($event) {
                                            _vm.isViewingContactInfo = false
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticStyle: {
                                              "fill-rule": "evenodd",
                                              "clip-rule": "evenodd",
                                              "stroke-linejoin": "round",
                                              "stroke-miterlimit": "2",
                                            },
                                            attrs: {
                                              width: "16px",
                                              height: "8px",
                                              viewBox: "0 0 16 8",
                                            },
                                          },
                                          [
                                            _c(
                                              "g",
                                              {
                                                attrs: {
                                                  transform:
                                                    "matrix(1,0,0,1,-1232.66,-1750.33)",
                                                },
                                              },
                                              [
                                                _c("path", {
                                                  staticStyle: {
                                                    fill: "rgb(255, 141, 64)",
                                                  },
                                                  attrs: {
                                                    d: "M1232.66,1753.77L1232.66,1753.77L1232.66,1754.97C1234.55,1754.97 1235.52,1756.6 1235.52,1757.54L1236.72,1757.54C1236.72,1756.72 1236.23,1755.51 1235.24,1754.68L1247.66,1754.68L1247.66,1753.48L1235.11,1753.48C1236.18,1752.57 1236.72,1751.18 1236.72,1750.33L1235.52,1750.33C1235.52,1751.29 1234.57,1753.19 1232.66,1753.19L1232.66,1753.77Z",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "tw-inline-block ml-1",
                                          },
                                          [_vm._v("Back")]
                                        ),
                                      ]
                                    ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      !_vm.isViewingContactInfo
                        ? [
                            _c(
                              "b-col",
                              { staticClass: "tw-mb-3", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c("b-col", { attrs: { cols: "6" } }, [
                                      _c(
                                        "small",
                                        {
                                          staticClass:
                                            "tw-block tw-text-[#4F4F4F] tw-mb-1",
                                        },
                                        [_vm._v("Date of Birth")]
                                      ),
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(_vm.patientInfo.birthday)
                                        ),
                                      ]),
                                    ]),
                                    _c("b-col", { attrs: { cols: "3" } }, [
                                      _c(
                                        "small",
                                        {
                                          staticClass:
                                            "tw-block tw-text-[#4F4F4F] tw-mb-1",
                                        },
                                        [_vm._v("Age")]
                                      ),
                                      _c("p", [
                                        _vm._v(_vm._s(_vm.patientInfo.age)),
                                      ]),
                                    ]),
                                    _c("b-col", { attrs: { cols: "3" } }, [
                                      _c(
                                        "small",
                                        {
                                          staticClass:
                                            "tw-block tw-text-[#4F4F4F] tw-mb-1",
                                        },
                                        [_vm._v("State")]
                                      ),
                                      _c("p", [
                                        _vm._v(_vm._s(_vm.patientInfo.state)),
                                      ]),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { staticClass: "tw-mb-3", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c("b-col", { attrs: { cols: "12" } }, [
                                      _c(
                                        "small",
                                        {
                                          staticClass:
                                            "tw-block tw-text-[#4F4F4F] tw-mb-1",
                                        },
                                        [_vm._v(" Patient Notes ")]
                                      ),
                                      _c("p", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.patientInfo.patientnotes ||
                                                "No Information Available"
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.patientInfo.unselectedproduct &&
                            _vm.patientInfo.unselectedproduct.length > 0
                              ? _c(
                                  "b-col",
                                  {
                                    staticClass: "tw-mb-3",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-row",
                                      [
                                        _c("b-col", { attrs: { cols: "12" } }, [
                                          _c(
                                            "small",
                                            {
                                              staticClass:
                                                "tw-block tw-text-[#4F4F4F] tw-mb-1",
                                            },
                                            [
                                              _vm._v(
                                                " Declined onboarding treatment "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "ul",
                                            { staticClass: "list-unstyled" },
                                            _vm._l(
                                              _vm.patientInfo.unselectedproduct,
                                              function (plan, i) {
                                                return _c("li", { key: i }, [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(plan.name || "") +
                                                      " "
                                                  ),
                                                ])
                                              }
                                            ),
                                            0
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.patientInfo.followupdatestamp
                              ? _c(
                                  "b-col",
                                  {
                                    staticClass: "tw-mb-3",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-row",
                                      [
                                        _c("b-col", { attrs: { cols: "12" } }, [
                                          _c(
                                            "small",
                                            {
                                              staticClass:
                                                "tw-block tw-text-[#4F4F4F] tw-mb-1",
                                            },
                                            [_vm._v(" Follow-up Date ")]
                                          ),
                                          _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.parseDate(
                                                    _vm.patientInfo
                                                      .followupdatestamp,
                                                    "MMMM d, yyyy"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.patientInfo.upsell &&
                            _vm.patientInfo.upsell.length > 0
                              ? _c(
                                  "b-col",
                                  {
                                    staticClass: "tw-mb-3",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-row",
                                      [
                                        _c("b-col", { attrs: { cols: "12" } }, [
                                          _c(
                                            "small",
                                            {
                                              staticClass:
                                                "tw-block tw-text-warning tw-mb-1 tw-uppercase",
                                            },
                                            [_vm._v(" Prescription Request ")]
                                          ),
                                          _c(
                                            "ul",
                                            { staticClass: "list-unstyled" },
                                            _vm._l(
                                              _vm.patientInfo.upsell,
                                              function (upsell, i) {
                                                return _c("li", { key: i }, [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(upsell.name) +
                                                      " "
                                                  ),
                                                  upsell.subscriptiontype
                                                    ? _c("span", [
                                                        _vm._v(
                                                          " (" +
                                                            _vm._s(
                                                              upsell.subscriptiontype
                                                            ) +
                                                            ") "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ])
                                              }
                                            ),
                                            0
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.patientInfo.followupdatestamp
                              ? _c(
                                  "b-col",
                                  {
                                    staticClass: "tw-mb-3",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-row",
                                      [
                                        _c("b-col", { attrs: { cols: "12" } }, [
                                          _c(
                                            "small",
                                            {
                                              staticClass:
                                                "tw-block tw-text-[#4F4F4F] tw-mb-1",
                                            },
                                            [_vm._v(" Preferred Shipment ")]
                                          ),
                                          _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.patientInfo
                                                    .preferredshipmentname ||
                                                    "No Information Available"
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "b-col",
                              { staticClass: "tw-mb-3", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c("b-col", { attrs: { cols: "12" } }, [
                                      _c(
                                        "small",
                                        {
                                          staticClass:
                                            "tw-block tw-text-[#4F4F4F] tw-mb-1",
                                        },
                                        [_vm._v(" Price Version ")]
                                      ),
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "text-secondary tw-cursor-pointer tw-inline-block tw-mb-4",
                                          on: {
                                            click: function ($event) {
                                              return _vm.$emit(
                                                "update:showPricing",
                                                true
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.patientInfo
                                                  .pricingversion === "v2"
                                                  ? "Version 2"
                                                  : "Version 1"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.patientInfo.othermedication &&
                            _vm.patientInfo.othermedication.length > 0
                              ? _c(
                                  "b-col",
                                  {
                                    staticClass: "tw-mb-3",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-row",
                                      { attrs: { "align-v": "center" } },
                                      [
                                        _c(
                                          "b-col",
                                          { attrs: { cols: "12", md: "12" } },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "tw-block tw-text-[#4F4F4F] tw-mb-1",
                                              },
                                              [
                                                _c("small", [
                                                  _vm._v("Other Medications"),
                                                ]),
                                              ]
                                            ),
                                            _vm.patientInfo.othermedication
                                              ? _c(
                                                  "div",
                                                  _vm._l(
                                                    _vm.patientInfo
                                                      .othermedication,
                                                    function (medication, i) {
                                                      return _c(
                                                        "h4",
                                                        { key: i },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                medication
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _c(
                                                  "h4",
                                                  { staticClass: "text-muted" },
                                                  [_vm._v("No records!")]
                                                ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.patientInfo.treamentplandetail &&
                            _vm.patientInfo.treamentplandetail.length === 0
                              ? _c(
                                  "b-col",
                                  {
                                    staticClass: "tw-mb-3",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-row",
                                      [
                                        _c("b-col", { attrs: { cols: "12" } }, [
                                          _c(
                                            "small",
                                            {
                                              staticClass:
                                                "tw-block tw-text-[#4F4F4F] tw-mb-1",
                                            },
                                            [_vm._v(" Recommended Treatment ")]
                                          ),
                                          _vm.patientInfo.desiredplan
                                            ? _c(
                                                "ul",
                                                {
                                                  staticClass: "list-unstyled",
                                                },
                                                _vm._l(
                                                  _vm.patientInfo.desiredplan,
                                                  function (plan, i) {
                                                    return _c(
                                                      "li",
                                                      { key: i },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              plan.name || ""
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm.patientInfo.preferredtreatment
                                            ? _c(
                                                "ul",
                                                {
                                                  staticClass: "list-unstyled",
                                                },
                                                [
                                                  _c("li", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.patientInfo
                                                            .preferredtreatment ||
                                                            ""
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _c(
                                                "ul",
                                                {
                                                  staticClass: "list-unstyled",
                                                },
                                                [
                                                  _c("li", [
                                                    _vm._v(
                                                      "Let doctor choose medication"
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.patientInfo.treamentplandetail &&
                            _vm.patientInfo.treamentplandetail.length > 0
                              ? _c(
                                  "b-col",
                                  {
                                    staticClass: "tw-mb-3",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-row",
                                      { attrs: { "align-v": "center" } },
                                      [
                                        _c(
                                          "b-col",
                                          { attrs: { cols: "12", md: "12" } },
                                          [
                                            _c(
                                              "h3",
                                              {
                                                staticClass:
                                                  "tw-block tw-text-[#4F4F4F] tw-mb-2",
                                                staticStyle: {
                                                  "font-size": "16px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " Active Treatment Plans "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              _vm._l(
                                                _vm.patientInfo
                                                  .treamentplandetail,
                                                function (treat, a) {
                                                  return _c(
                                                    "div",
                                                    { key: a },
                                                    [
                                                      _c(
                                                        "b-row",
                                                        [
                                                          _c(
                                                            "b-col",
                                                            {
                                                              staticClass:
                                                                "mb-2",
                                                              attrs: {
                                                                cols: "12",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-black",
                                                                  staticStyle: {
                                                                    "font-size":
                                                                      "14px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        treat.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "tw-block",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.moment
                                                                          .unix(
                                                                            treat.date
                                                                          )
                                                                          .format(
                                                                            "MM/DD/YY"
                                                                          )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "b-row",
                                                                [
                                                                  _c(
                                                                    "b-col",
                                                                    [
                                                                      _c(
                                                                        "b-badge",
                                                                        {
                                                                          class:
                                                                            [
                                                                              "mr-2",
                                                                              treat.status ==
                                                                              "PAUSED"
                                                                                ? "badge-warning"
                                                                                : treat.status ==
                                                                                  "CANCELLED"
                                                                                ? "badge-danger"
                                                                                : treat.status ==
                                                                                  "ACCEPTED"
                                                                                ? "badge-success"
                                                                                : treat.status ==
                                                                                  "PRESCRIBED"
                                                                                ? "badge-success"
                                                                                : treat.status ==
                                                                                  "SCHEDULED"
                                                                                ? "badge-purple"
                                                                                : "",
                                                                            ],
                                                                          staticStyle:
                                                                            {
                                                                              padding:
                                                                                "5px",
                                                                              "border-radius":
                                                                                "9999px",
                                                                              display:
                                                                                "inline-block",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "small",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              treat.status
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        : [
                            _c(
                              "b-col",
                              { staticClass: "tw-mb-3", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c("b-col", { attrs: { cols: "6" } }, [
                                      _c(
                                        "small",
                                        {
                                          staticClass:
                                            "tw-block tw-text-[#4F4F4F] tw-mb-1",
                                        },
                                        [_vm._v("Date of Birth")]
                                      ),
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(_vm.patientInfo.birthday)
                                        ),
                                      ]),
                                    ]),
                                    _c("b-col", { attrs: { cols: "3" } }, [
                                      _c(
                                        "small",
                                        {
                                          staticClass:
                                            "tw-block tw-text-[#4F4F4F] tw-mb-1",
                                        },
                                        [_vm._v("Age")]
                                      ),
                                      _c("p", [
                                        _vm._v(_vm._s(_vm.patientInfo.age)),
                                      ]),
                                    ]),
                                    _c("b-col", { attrs: { cols: "3" } }, [
                                      _c(
                                        "small",
                                        {
                                          staticClass:
                                            "tw-block tw-text-[#4F4F4F] tw-mb-1",
                                        },
                                        [_vm._v("State")]
                                      ),
                                      _c("p", [
                                        _vm._v(_vm._s(_vm.patientInfo.state)),
                                      ]),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { staticClass: "tw-mb-3", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c("b-col", { attrs: { cols: "12" } }, [
                                      _c(
                                        "small",
                                        {
                                          staticClass:
                                            "tw-block tw-text-[#4F4F4F] tw-mb-1",
                                        },
                                        [_vm._v("Address")]
                                      ),
                                      _c("p", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              `${
                                                _vm.patientInfo.defaultaddress
                                                  .address1
                                              } ${
                                                _vm.patientInfo.defaultaddress
                                                  .address2 || ""
                                              } ${
                                                _vm.patientInfo.defaultaddress
                                                  .city
                                              } ${
                                                _vm.patientInfo.defaultaddress
                                                  .zipcode
                                              }` || ""
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.patientInfo.defaultaddress &&
                            _vm.patientInfo.defaultaddress.timezone
                              ? _c(
                                  "b-col",
                                  {
                                    staticClass: "tw-mb-3",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-row",
                                      [
                                        _c("b-col", { attrs: { cols: "12" } }, [
                                          _c(
                                            "small",
                                            {
                                              staticClass:
                                                "tw-block tw-text-[#4F4F4F] tw-mb-1",
                                            },
                                            [_vm._v(" Timezone ")]
                                          ),
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.patientInfo.defaultaddress
                                                  .timezone
                                              )
                                            ),
                                          ]),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "b-col",
                              { staticClass: "tw-mb-3", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c("b-col", { attrs: { cols: "12" } }, [
                                      _c(
                                        "small",
                                        {
                                          staticClass:
                                            "tw-block tw-text-[#4F4F4F] tw-mb-1",
                                        },
                                        [_vm._v(" Email Address ")]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticStyle: {
                                            "word-break": "break-all",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.patientInfo.email))]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { staticClass: "tw-mb-3", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c("b-col", { attrs: { cols: "12" } }, [
                                      _c(
                                        "small",
                                        {
                                          staticClass:
                                            "tw-block tw-text-[#4F4F4F] tw-mb-1",
                                        },
                                        [_vm._v(" Contact Number ")]
                                      ),
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.patientInfo.phoneno ||
                                              "No Information Available"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "tw-flex-shrink-0 tw-sticky tw-bottom-0 tw-pt-4",
                },
                [
                  _vm.$route.name === "patientmessage"
                    ? _c(
                        "b-row",
                        { staticClass: "tw-mb-6" },
                        [
                          !_vm.currentDoctor.id
                            ? _c(
                                "b-col",
                                {
                                  staticClass: "tw-mb-2",
                                  attrs: { cols: "12" },
                                },
                                [
                                  !_vm.patientInfo.isbanned
                                    ? _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            block: "",
                                            variant: "secondary",
                                            pill: "",
                                            to: `/prescriptions/order/${_vm.patientInfo.id}`,
                                          },
                                        },
                                        [_vm._v(" Prescribe ")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-col",
                            { staticClass: "tw-mb-2", attrs: { cols: "12" } },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    block: "",
                                    variant: "outline-secondary",
                                    pill: "",
                                    to: `/patients/${_vm.patientInfo.id}/treatments`,
                                  },
                                },
                                [_vm._v(" View Profile ")]
                              ),
                            ],
                            1
                          ),
                          !_vm.currentDoctor.id
                            ? _c(
                                "b-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        block: "",
                                        variant: "outline-secondary",
                                        pill: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$emit("show-dosespot")
                                        },
                                      },
                                    },
                                    [_vm._v(" Open Dosespot ")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("portal-target", {
                                attrs: { name: "needsreply" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$route.name === "treatments"
                    ? _c("div", { staticClass: "tw-mb-6" }, [
                        _c(
                          "div",
                          { staticClass: "tw-flex tw-justify-center" },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { block: "", variant: "link", pill: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit(
                                      "switch-view",
                                      "prescription-history"
                                    )
                                  },
                                },
                              },
                              [_vm._v(" Prescription ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "tw-flex tw-justify-center" },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { block: "", variant: "link", pill: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("switch-view", "reminders")
                                  },
                                },
                              },
                              [_vm._v(" reminders ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "tw-flex tw-justify-center" },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { block: "", variant: "link", pill: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("switch-view", "followup")
                                  },
                                },
                              },
                              [_vm._v(" follow-up history ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "tw-flex tw-justify-center" },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  block: "",
                                  variant: "link",
                                  pill: "",
                                  to: `/patients/${_vm.patientInfo.id}/intake`,
                                },
                              },
                              [_vm._v(" Intake History ")]
                            ),
                          ],
                          1
                        ),
                        !_vm.currentDoctor.id
                          ? _c(
                              "div",
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      block: "",
                                      variant: "secondary",
                                      pill: "",
                                      to: `/patients/${_vm.patientInfo.id}/message`,
                                    },
                                  },
                                  [_vm._v(" Chat with patient ")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }