<template>
  <SideModal
    :show="showPricingModal"
    @exit="$emit('update:showPricingModal', false)"
    body-class="pricing-body"
    title="Pricing"
    :z-index="9999"
    :show-header="false"
    v-click-outside="config"
  >
    <template #body>
      <div class="pt-3 px-3">
        <div class="py-5 d-flex justify-content-between align-items-center">
          <p class="text-primary mb-0 tw-text-xl">
            Patient Pricing: {{ pricingversion }}
          </p>
          <a
            @click.prevent="$emit('update:showPricingModal', false)"
            class="text-uppercase tw-cursor-pointer"
            style="letter-spacing: 0.1rem"
          >
            Close
          </a>
        </div>
        <div>
          <b-table
            hover
            :items="pricingList"
            :fields="fields"
            responsive
            small
            style="height: calc(100vh - 140px)"
          ></b-table>
        </div>
      </div>
    </template>
  </SideModal>
</template>

<script>
import SideModal from "@/components/partials/SideModal";
import vClickOutside from "v-click-outside";
import { PrescriptionService } from "@/services/prescription.service.js";

export default {
  props: {
    showPricingModal: {
      type: Boolean,
      default: false,
    },
    patientId: {
      type: String,
      default: "",
    },
    pricingversion: {
      type: String,
      default: "",
    },
  },
  components: {
    SideModal,
  },
  data() {
    return {
      vcoConfig: {
        handler: this.onClickOutside,
        isActive: false,
      },
      pricingList: [],
      fields: ["name", "days", "price"],
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  computed: {
    config() {
      return { ...this.vcoConfig };
    },
  },
  watch: {
    async showPricingModal(val) {
      if (val) {
        this.vcoConfig.isActive = true;

        const { data } = await PrescriptionService.getProductDosageAndUnit(
          this.patientId
        );
        let transformedData = [];
        for (let item of data) {
          for (let { dosage, detail } of item.details) {
            if (dosage) {
              const transformedItems = detail
                .map((val) => ({
                  id: item.id,
                  name: `${item.name} ${dosage} - ${
                    val.quantity
                  } ${this.pluralize(Number(val.quantity), val.ship_unit)}`,
                  price: `$${val.price.toFixed(2)}`,
                  days: val.intervaldisplay ? val.intervaldisplay : "N/A",
                }))
                .filter(
                  (val) =>
                    !transformedData.find(
                      (obj) =>
                        val.id === obj?.id &&
                        val.name === obj?.name &&
                        val.price === obj?.price &&
                        val.quantity === obj?.quantity
                    )
                );

              transformedData.push(...transformedItems);
            }
          }
          transformedData.push({
            id: "",
            name: "",
            price: "",
            days: "",
            quantity: "",
          });
        }
        this.pricingList = transformedData;
      } else {
        this.vcoConfig.isActive = false;
      }
    },
  },
  methods: {
    onClickOutside() {
      this.$emit("update:showPricingModal", false);
    },
    pluralize(amount, singular, plural = `${singular}s`) {
      return amount === 1 ? singular : plural;
    },
  },
};
</script>

<style></style>
