var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("SideModal", {
    directives: [
      {
        name: "click-outside",
        rawName: "v-click-outside",
        value: _vm.config,
        expression: "config",
      },
    ],
    attrs: {
      show: _vm.showPricingModal,
      "body-class": "pricing-body",
      title: "Pricing",
      "z-index": 9999,
      "show-header": false,
    },
    on: {
      exit: function ($event) {
        return _vm.$emit("update:showPricingModal", false)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { staticClass: "pt-3 px-3" }, [
              _c(
                "div",
                {
                  staticClass:
                    "py-5 d-flex justify-content-between align-items-center",
                },
                [
                  _c("p", { staticClass: "text-primary mb-0 tw-text-xl" }, [
                    _vm._v(
                      " Patient Pricing: " + _vm._s(_vm.pricingversion) + " "
                    ),
                  ]),
                  _c(
                    "a",
                    {
                      staticClass: "text-uppercase tw-cursor-pointer",
                      staticStyle: { "letter-spacing": "0.1rem" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.$emit("update:showPricingModal", false)
                        },
                      },
                    },
                    [_vm._v(" Close ")]
                  ),
                ]
              ),
              _c(
                "div",
                [
                  _c("b-table", {
                    staticStyle: { height: "calc(100vh - 140px)" },
                    attrs: {
                      hover: "",
                      items: _vm.pricingList,
                      fields: _vm.fields,
                      responsive: "",
                      small: "",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }